import * as React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { routeNames } from 'routes';
import randomNFT from './../../assets/img/random.gif';
import { useGetNetworkConfig } from '@elrondnetwork/dapp-core/hooks';
import { PageState } from '@elrondnetwork/dapp-core/UI';

const Transaction = () => {
  const { search } = useLocation();
  const { network } = useGetNetworkConfig();

  const query = new URLSearchParams(search);
  const { txHash } = Object.fromEntries(query);

  const [URLs, setURLs] = React.useState<Array<string>>([]);
  const [IDs, setIDs] = React.useState<Array<number>>([]);

  const getInfo = async () => {
    await fetch(`${network.apiAddress}/transactions/${txHash}`)
      .then((res) => res.json())
      .then((responseText) => {
        console.log(responseText);
        for (const op of responseText['operations']) {
          if (op['identifier']) {
            const nonce = op['identifier'].split('-')[2];
            const newID = parseInt(nonce, 16);
            setIDs((prevState) => [...prevState, newID]);
            setURLs((prevState) => [
              ...prevState,
              `https://media.elrond.com/nfts/asset/QmWV5jdF4jWMArAXzUk2b6wDXSmrcKx1DohbeHNLKCDxLz/${newID}.png`
            ]);
          }
        }
      });
  };

  React.useEffect(() => {
    getInfo();
  }, []);

  const handleChange = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const card = target.parentElement!.parentElement!;
    card.classList.add('active');
    const index = parseInt(card.parentElement?.dataset.index as string);
    card.parentElement!.lastElementChild!.innerHTML = `8-Bit Heroes #${IDs[index]}`;
  };

  return (
    <div className='transactions-container'>
      <div className='nfts-container'>
        {URLs.map((url, index) => {
          return (
            <div
              className='nft-container'
              data-index={index}
              key={index}
              onClick={handleChange}
            >
              <div className='nft-card'>
                <div className='card-front'>
                  <img src={randomNFT} className='front' />
                </div>
                <div className='card-back'>
                  <img src={url} className='back' />
                </div>
              </div>
              <p>???</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Transaction;
