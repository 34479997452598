import * as React from 'react';
import {
  useGetNetworkConfig,
  useTrackTransactionStatus
} from '@elrondnetwork/dapp-core/hooks';
import { sendTransactions } from '@elrondnetwork/dapp-core/services';
import { refreshAccount } from '@elrondnetwork/dapp-core/utils';
import { contractAddress } from 'config';

const DROP_SIZE = 500;
const DROP_PRICE = 0.6;
const DROP_MAX = 20;

const Actions = () => {
  const [nftsMinted, setNftsMinted] = React.useState(0);
  const [quantity, setQuantity] = React.useState(1);
  const { network } = useGetNetworkConfig();

  const [transactionSessionId, setTransactionSessionId] = React.useState<
    string | null
  >(null);

  useTrackTransactionStatus({
    transactionId: transactionSessionId,
    onSuccess: async () => {
      const txs = await fetch(
        `${network.apiAddress}/transactions?receiver=${contractAddress}`
      ).then((res) => {
        console.log(res);
        return res.json();
      });
      console.log('await');
      window.location.href = `/transaction?txHash=${txs[0]['txHash']}`;
    }
  });

  const getInfo = async () => {
    const url = `${network.apiAddress}/accounts/${contractAddress}/nfts/count`;
    const data = await fetch(url).then((res) => res.json());
    isNaN(data) ? setNftsMinted(DROP_SIZE) : setNftsMinted(DROP_SIZE - data);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    getInfo();
  }, []);

  const send = async () => {
    const transactions = {
      receiver: contractAddress,
      data: `mint@0${quantity}`,
      value: (quantity * DROP_PRICE * 10 ** 18).toString(),
      gasLimit: 600000000
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions,
      callbackRoute: '/transaction',
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  const handleChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    const self = event.target as HTMLElement;
    if (self.id === 'minus') {
      if (quantity > 1) setQuantity(quantity - 1);
    } else if (self.id === 'plus') {
      if (quantity < 8) {
        if (quantity < DROP_SIZE - nftsMinted) setQuantity(quantity + 1);
      }
    }
  };

  return (
    <div className='text-white'>
      {nftsMinted !== DROP_SIZE && (
        <>
          <div className='topInfo'>
            <div>Price: {DROP_PRICE} EGLD</div>
            <div>Max {DROP_MAX} NFTs per wallet</div>
          </div>
          <div className='input-qty'>
            <button id='minus' onClick={handleChange}>
              -
            </button>
            <span>{quantity}</span>
            <button id='plus' onClick={handleChange}>
              +
            </button>
          </div>
          <button className='mint-btn' onClick={() => send()}>
            Mint
          </button>
        </>
      )}
      {nftsMinted === DROP_SIZE && (
        <button className='mint-btn' disabled>
          SOLD OUT
        </button>
      )}

      <div>
        {nftsMinted}/{DROP_SIZE} NFTs minted
      </div>
    </div>
  );
};

export default Actions;
